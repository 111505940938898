<!--初始化数据-->
<template>
    <div class="SysIn" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: left" v-model="form">
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable size="small">
                                <el-option value="" label="请选择" />
                                <el-optiosn
                                    v-for="dept in meta.storeDepts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" v-if="hasPrivilege('menu.purchase.sysIn.open')" @click="handleQuery" size="small">
                查询
            </el-button>
            <el-button
                type="primary"
                v-if="hasPrivilege('menu.purchase.sysIn.create')"
                @click="handleCreate"
                size="small"
                >新建
            </el-button>
            <el-button
                type="primary"
                v-if="hasPrivilege('menu.purchase.sysIn.review')"
                @click="handleAudit"
                size="small"
                :disabled="disableReviewBtn"
                >审核
            </el-button>
            <el-button
                type="primary"
                v-if="hasPrivilege('menu.purchase.sysIn.stock')"
                @click="handleIn"
                size="small"
                :disabled="disableStockBtn"
            >
                入库
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.purchase.sysIn.export')"
            >
                导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <ef-review-biz ref="review" baseUrl="/purchase/sysIn" @completed="handleQuery" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />

                <el-table-column prop="code" label="编码" width="180" />
                <el-table-column prop="deptName" label="机构名称" width="180" />
                <el-table-column prop="reviewStatus" label="单据状态" sortable width="180">
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="stockStatus" label="入库状态" sortable width="180">
                    <template slot-scope="scope">
                        <span>{{ scope.row.stockStatus | stockInStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="creator" label="创建人" width="180" />
                <el-table-column prop="createTime" label="创建时间" width="140" />
                <el-table-column label="操作" min-width="275" header-align="center">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            v-if="hasPrivilege('menu.purchase.sysIn.open')"
                            @click="rowDetail(scope.row)"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            v-if="hasPrivilege('menu.purchase.sysIn.edit')"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            :disabled="scope.row.disableEditBtn"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            v-if="hasPrivilege('menu.purchase.sysIn.delete')"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            :disabled="scope.row.disableDeleteBtn"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import StockBizCommon from 'js/StockBizCommon';
import EfReviewBiz from 'components/EfReviewBiz';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'SysIn',
    components: { EfReviewBiz },
    data() {
        return {
            selectedBizArr: [],
            form: {
                deptCode: '',
                deptType: '',
                search: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },

            auditCodes: [],
            audit: {
                auditDate: '',
                advice: '1',
                remark: '',
            },
            loading: false,
            tableData: [],
            url: {
                page: '/purchase/sysIn/page',
                delete: '/purchase/sysIn/delete',
                review: '/purchase/sysIn/review/',
            },
            meta: {
                storeDepts: [],
            },
        };
    },
    mounted() {
        (async () => {
            this.meta.storeDepts = await this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE);
            this.handleQuery();
        })();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    computed: {
        //禁用/启用按钮
        disableReviewBtn() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const disableReviewBtnFlag = this.selectedBizArr.findIndex((e) => {
                return e.disableReviewBtn;
            });
            return disableReviewBtnFlag >= 0;
        },
        disableStockBtn() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const disableStockBtnFlag = this.selectedBizArr.findIndex((e) => {
                return e.disableStockBtn;
            });
            return disableStockBtnFlag >= 0;
        },
    },
    methods: {
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '初始化数据', '/purchase/sysIn/export', this.form, codes);
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleIn() {
            const _this = this;
            if (this.selectedBizArr.length !== 1) {
                this.$message.error('选择单据数量必须为一条');
            } else if (this.selectedBizArr[0].reviewStatus !== 2) {
                this.$message.error('请勾选已审核商品');
            } else if (this.selectedBizArr[0].stockStatus !== 0) {
                this.$message.error('商品仅能入库一次');
            } else {
                this.$confirm('确认入库该单据么?入库后该单据商品库存将立即变更,该操作不可撤销,请小心操作!!!', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        UrlUtils.PatchRemote(
                            this,
                            '/purchase/sysIn/inAndPriceChange',
                            { codes: this.selectedBizArr[0].code },
                            null,
                            () => {
                                _this.$message.success('保存成功');
                                _this.handleQuery();
                            }
                        );
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消入库',
                        });
                    });
            }
        },
        handleQuery() {
            const params = { params: this.form };
            Util.queryTable(this, this.url.page, params, (data) => {
                this.tableData = data.data.map((e) => {
                    return {
                        ...e,
                        //根据状态设置操作按钮禁用或启用
                        disableEditBtn: !StockBizCommon.validateCanEdit(e.reviewStatus),
                        disableDeleteBtn: !StockBizCommon.validateCanDelete(e.reviewStatus),
                        disableReviewBtn: !StockBizCommon.validateCanReview(e.reviewStatus),
                        disableStockBtn: !StockBizCommon.validateCanChangeStock(e.reviewStatus, e.stockStatus),
                    };
                });
                this.page.total = data.count;
            });
        },
        handleCreate() {
            Util.nameJump(this, 'menu.purchase.sysIn.create', ['商品管理', '初始化数据管理', '新建初始化数据']);
        },
        handleAudit() {
            this.$refs.review.open(this.$refs.table.selection);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.purchase.sysIn.detail', ['商品管理', '初始化数据管理', '初始化数据详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            if (row.reviewStatus == 0) {
                Util.nameJump(this, 'menu.purchase.sysIn.edit', ['商品管理', '初始化数据管理', '编辑初始化数据'], {
                    form: row,
                    code: row.code,
                });
            } else {
                this.$message.error('非待审核状态不可编辑');
            }
        },
        handleDelete(row) {
            if (row.reviewStatus == 0) {
                UrlUtils.DeleteRemote(this, this.url.delete, row.code);
            } else {
                this.$message.error('非待审核状态不可删除');
            }
        },
        handleSelectionChange(selections) {
            this.selectedBizArr = selections;
        },
    },
};
</script>

<style scoped>
.SysIn .el-form-item {
    margin-bottom: 0;
}
</style>
